import { useEffect, useState } from "react"

import { v4 as generateRandomUid } from "uuid"

const extractUidFromCookies = () => {
  const cookies = document.cookie.split("; ")
  const uidCookie = cookies.find(cookie => cookie.includes("paia-widget-uid"))

  if (uidCookie) {
    const uid = uidCookie.split("=")[1]
    return uid
  }
}

const addUidToCookies = (uid: string) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  currentDate.setFullYear(currentYear + 10)

  document.cookie = `paia-widget-uid=${uid}; expires=${currentDate.toUTCString()}; SameSite=None; Secure`
}

const isRenderedInsideIframe = () => {
  if (!window) {
    throw new Error(
      "isRenderedInsideIframe can only be called inside a useEffect hook"
    )
  }

  try {
    return window.self !== window.top
  } catch {
    return true
  }
}

const getUidBasedOnCookies = () => {
  const uidExtractedFromCookies = extractUidFromCookies()

  if (uidExtractedFromCookies) {
    return uidExtractedFromCookies
  }

  const generatedUid = generateRandomUid()
  addUidToCookies(generatedUid)

  return generatedUid
}

const useUserId = () => {
  const [uid, setUid] = useState<string>("")

  useEffect(() => {
    if (!isRenderedInsideIframe()) {
      const uid = getUidBasedOnCookies()

      setUid(uid)
    }

    window.parent.postMessage("paia-widget-loaded", "*")

    const handleWindowMessage = (e: MessageEvent) => {
      if (typeof e.data !== "object") {
        return
      }

      if (!e.data.hasOwnProperty("uid")) {
        return
      }

      if (typeof e.data.uid !== "string") {
        setUid(getUidBasedOnCookies())

        return
      }

      if (!e.data.uid.trim()) {
        setUid(getUidBasedOnCookies())

        return
      }

      setUid(e.data.uid)
    }

    window.addEventListener("message", handleWindowMessage)
    return () => window.removeEventListener("message", handleWindowMessage)
  }, [])

  return uid
}

export default useUserId
