import styled from "styled-components"

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  list-style: none;
  padding: 0 18px 18px 18px;
  margin: 2px;
  margin-bottom: 2px;
  overflow-y: auto;
`
