import styled, { createGlobalStyle } from "styled-components"

type GlobalProps = {
  $primaryColor?: string
}

export const GlobalStyles = createGlobalStyle<GlobalProps>`

  :root {
    --paia-color-primary: ${({ $primaryColor }) => $primaryColor};
  }

  pre {
    background-color: white;
    padding: 7px;
    border-radius: 2px;
    overflow-wrap: normal;
    hyphens: none;
    line-height: 1.3;
    tab-size: 2;
    text-align: left;
    white-space: pre-wrap;
    word-break: normal;
    word-spacing: normal;
    overflow: auto;
    color: rgb(0 0 0);
    font-size: 90%;
  }

  p {
    margin: 0;
  }

  code {
    font-family: 'Courier New', monospace;
    background-color: #fff;
    padding: 2px 4px;
    border-radius: 2px;
    font-size:13px;
    word-wrap: normal;
    -webkit-hyphens: none;
    hyphens: none;
    line-height: 1.3;
    tab-size:2;
    text-align: left;
    white-space: pre-wrap;
    word-break: normal;
    word-spacing: normal;
    color: #000;
    font-size: 90%;
  }

  .ask-select .select__control {
    border: 2px solid #e8ecef !important;
    box-shadow: none;
    font-size: 90%;
  }

  .ask-select .select__menu {
    border: 2px solid #e8ecef;
  }

  .ask-select .select__single-value {
    font-size: 12px;
    font-weight: 500;
    color: #141718;
  }

  .ask-select .select__option {
    color: #141718;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  .ask-select .select__option--is-focused {
    background-color: #e8ecef;
  }

  .ask-select .select__option--is-selected {
    background-color: #141718;
    color: white;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: var(--paia-color-primary);
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`
