import { useEffect, useState } from "react"
import { useAppState } from "../context"

const useInitalMessageMountDelay = () => {
  const { formType, messages } = useAppState()

  const shouldDelay = formType === "chat" && !messages.length

  const [mountInitialMessage, setMountInitialMessage] = useState(false)

  useEffect(() => {
    if (!shouldDelay) {
      setMountInitialMessage(true)
      return
    }

    const timeout = setTimeout(() => {
      setMountInitialMessage(true)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [formType, messages])

  return { mountInitialMessage }
}

export default useInitalMessageMountDelay
