import { List } from "./styles"

import { Link } from "./components"

import { useAppState } from "../../context"

import Loader from "../loader"

const SearchResults = () => {
  const {
    searchState: { results, error, loading },
  } = useAppState()

  if (loading) {
    return (
      <List>
        <Loader />
      </List>
    )
  }

  if (error) {
    return (
      <List>
        <li>{error}</li>
      </List>
    )
  }

  if (!results) {
    return null
  }

  if (!results.length) {
    return <List>No results found</List>
  }

  return (
    <List className="styled-scrollbar">
      {results.map((props, index) => (
        <Link position={index + 1} {...props} key={index} />
      ))}
    </List>
  )
}

export default SearchResults
