import { type CSSProperties } from "react"

const icons = {
  logo: {
    height: 28,
    width: 28,
    children: (
      <>
        <path
          d="M13.1,15.3h-2.6l0,0c-1.2,0-2.3-0.8-2.7-1.9
          c-0.2-0.7-0.4-1.4-0.4-2.3s0.1-1.6,0.4-2.3C8.2,7.8,9.3,7,10.5,7h7c1.2,0,2.3,0.8,2.7,1.9c0.2,0.7,0.4,1.4,0.4,2.3s-0.1,1.6-0.4,2.3
          c-0.4,1.1-1.5,1.9-2.7,1.9h-2.6v1.8h2.5c0.9,0,1.8,0.5,2.2,1.4l0.6,1.3c0.3,0.6-0.2,1.2-0.8,1.2c-0.3,0-0.6-0.2-0.8-0.5L18,19.2
          c-0.1-0.2-0.3-0.4-0.6-0.4h-6.8c-0.3,0-0.5,0.1-0.6,0.4l-0.6,1.3C9.2,20.8,8.9,21,8.6,21c-0.6,0-1.1-0.7-0.8-1.2l0.6-1.3
          c0.4-0.8,1.2-1.4,2.2-1.4h2.5V15.3z M10.5,8.8c-0.5,0-0.9,0.3-1,0.7c-0.1,0.4-0.3,0.9-0.3,1.7s0.1,1.3,0.3,1.7
          c0.2,0.4,0.6,0.7,1,0.7h7c0.5,0,0.9-0.3,1-0.7c0.1-0.4,0.3-0.9,0.3-1.7s-0.1-1.3-0.3-1.7c-0.2-0.4-0.6-0.7-1-0.7H10.5z M15.8,12
          h-3.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h3.5c0.5,0,0.9,0.4,0.9,0.9S16.2,12,15.8,12z"
          fill="#fefefe"
        />
      </>
    ),
  },
  reload: {
    height: 12,
    width: 12,
    children: (
      <>
        <path d="M3.32334 3.84431H4.45312C4.85068 3.84431 5.17187 4.1655 5.17187 4.56306C5.17187 4.96062 4.85068 5.28181 4.45312 5.28181H1.57812C1.18057 5.28181 0.859375 4.96062 0.859375 4.56306V1.68806C0.859375 1.2905 1.18057 0.969312 1.57812 0.969312C1.97568 0.969312 2.29687 1.2905 2.29687 1.68806V2.83806L2.69219 2.44275C4.65752 0.477417 7.84248 0.477417 9.80781 2.44275C11.7731 4.40808 11.7731 7.59304 9.80781 9.55837C7.84248 11.5237 4.65752 11.5237 2.69219 9.55837C2.41143 9.27761 2.41143 8.82166 2.69219 8.54089C2.97295 8.26013 3.42891 8.26013 3.70967 8.54089C5.11348 9.9447 7.38877 9.9447 8.79258 8.54089C10.1964 7.13708 10.1964 4.86179 8.79258 3.45798C7.38877 2.05417 5.11348 2.05417 3.70967 3.45798L3.32334 3.84431Z" />
      </>
    ),
  },
  user: {
    height: 28,
    width: 28,
    children: (
      <>
        <path
          d="M14.1,7c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S16,7,14.1,7z M14.1,8.4
          c1.2,0,2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1S12,11.7,12,10.5S12.9,8.4,14.1,8.4z M11.6,14.9c-1.8,0.5-3.1,2.1-3.1,4v1.4
          c0,0.4,0.3,0.7,0.7,0.7H19c0.4,0,0.7-0.3,0.7-0.7v-1.4c0-1.9-1.3-3.5-3.1-4c-0.2,0-0.3,0-0.5,0c-0.6,0.3-1.3,0.4-2,0.4
          c-0.7,0-1.4-0.1-2-0.4C11.9,14.9,11.8,14.9,11.6,14.9z M11.9,16.3c0.7,0.3,1.4,0.5,2.2,0.5c0.8,0,1.5-0.2,2.2-0.5
          c1.2,0.4,2,1.4,2,2.6v0.7H9.9v-0.7C9.9,17.7,10.7,16.7,11.9,16.3z"
          fill="#fefefe"
        />
      </>
    ),
  },
  search: {
    height: 12,
    width: 12,
    children: (
      <>
        <path
          d="M10.5 10.5L8.75005 8.75M10 5.75C10 8.09721 8.09721 10 5.75 10C3.40279 10 1.5 8.09721 1.5 5.75C1.5 3.40279 3.40279 1.5 5.75 1.5C8.09721 1.5 10 3.40279 10 5.75Z"
          stroke="#FEFEFE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  },
  chat: {
    width: 12,
    height: 12,
    children: (
      <>
        <path
          d="M3.35395 7.12493H5.62457V8.62497H3.45714C2.65843 8.62497 1.93082 9.08625 1.59155 9.80993L1.06474 10.9312C0.830367 11.4281 1.19409 12 1.74353 12C2.03414 12 2.29846 11.8313 2.42231 11.5688L2.94912 10.4475C3.04093 10.2507 3.23973 10.125 3.45724 10.125H9.29218C9.50968 10.125 9.70849 10.2507 9.8003 10.4475L10.3271 11.5688C10.4508 11.8313 10.7153 12 11.0059 12C11.5552 12 11.9191 11.4282 11.6847 10.9312L11.1579 9.80993C10.8185 9.08615 10.091 8.62497 9.29228 8.62497H7.12484V7.12493H9.39547C10.4211 7.12493 11.3436 6.47622 11.6904 5.51059C11.8967 4.93878 11.9998 4.28431 11.9998 3.56247C11.9998 2.84063 11.8947 2.18434 11.6904 1.61434C11.3436 0.648716 10.4211 0 9.39547 0H3.35428C2.32863 0 1.40616 0.648716 1.05932 1.61434C0.853061 2.18615 0.75 2.84063 0.75 3.56247C0.75 4.28431 0.855023 4.94059 1.05932 5.5124C1.40616 6.47802 2.32863 7.1249 3.35428 7.1249L3.35395 7.12493ZM2.47086 2.12069C2.60583 1.74753 2.95832 1.5001 3.35395 1.5001H9.39513C9.7926 1.5001 10.1451 1.74755 10.2782 2.12069C10.3963 2.44884 10.4995 2.91941 10.4995 3.5625C10.4995 4.20559 10.3965 4.67632 10.2782 5.00431C10.1433 5.37747 9.79076 5.6249 9.39513 5.6249H3.35395C2.95648 5.6249 2.60402 5.37745 2.47086 5.00431C2.35276 4.67616 2.24956 4.20559 2.24956 3.5625C2.24956 2.91941 2.35262 2.44868 2.47086 2.12069Z"
          fill="#FEFEFE"
        />
      </>
    ),
  },
  send: {
    width: 18,
    height: 18,
    children: (
      <>
        <path
          d="M17.9865 1.29566L15.7364 15.8895C15.6837 16.2318 15.4771 16.5301 15.175 16.6999C15.0047 16.7955 14.8139 16.844 14.6237 16.844C14.4765 16.844 14.3312 16.8153 14.1916 16.757L9.8813 14.9605L8.09319 17.6427C7.95608 17.8773 7.72756 18.0004 7.48145 18.0004C7.07714 18.0004 6.75018 17.6734 6.75018 17.2691V13.8877C6.75018 13.6375 6.83357 13.3944 6.98721 13.1972L14.6255 3.37487L4.2997 12.667L0.692196 11.1623C0.296604 10.997 0.0285108 10.6243 0.00214273 10.1638C-0.0242254 9.70322 0.194419 9.32985 0.566772 9.11715L16.3173 0.148471C16.6932 -0.0663059 17.1569 -0.0465474 17.5127 0.197903C17.8685 0.442353 18.0533 0.868848 17.9865 1.29566Z"
          fill="var(--paia-color-primary)"
        />
      </>
    ),
  },
  pause: {
    width: 16,
    height: 16,
    children: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 0.666016C3.94926 0.666016 0.666016 3.94926 0.666016 7.99935C0.666016 12.0494 3.94926 15.3327 7.99935 15.3327C12.0494 15.3327 15.3327 12.0494 15.3327 7.99935C15.3327 3.94926 12.0494 0.666016 7.99935 0.666016ZM6.99935 5.99935C6.99935 5.63116 6.70087 5.33268 6.33268 5.33268C5.96449 5.33268 5.66602 5.63116 5.66602 5.99935V9.99935C5.66602 10.3675 5.96449 10.666 6.33268 10.666C6.70087 10.666 6.99935 10.3675 6.99935 9.99935V5.99935ZM10.3327 5.99935C10.3327 5.63116 10.0342 5.33268 9.66601 5.33268C9.29782 5.33268 8.99935 5.63116 8.99935 5.99935V9.99935C8.99935 10.3675 9.29782 10.666 9.66601 10.666C10.0342 10.666 10.3327 10.3675 10.3327 9.99935V5.99935Z"
        fill="black"
      />
    ),
  },
  chevronDown: {
    width: 8,
    height: 6,
    children: (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1 1.5L4 4.5L7 1.5"
        stroke="#141718"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  },
  chevronRight: {
    width: 6,
    height: 8,
    children: (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1.5 7L4.5 4L1.5 1"
        stroke="#141718"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  },
  close: {
    width: 11,
    height: 10,
    children: (
      <path
        d="M9.97206 1.47206C10.2324 1.21171 10.2324 0.789596 9.97206 0.529247C9.71171 0.268897 9.2896 0.268897 9.02925 0.529247L5.50065 4.05784L1.97206 0.529247C1.71171 0.268897 1.2896 0.268897 1.02925 0.529247C0.768897 0.789596 0.768897 1.21171 1.02925 1.47206L4.55784 5.00065L1.02925 8.52925C0.768897 8.7896 0.768897 9.21171 1.02925 9.47206C1.2896 9.73241 1.71171 9.73241 1.97206 9.47206L5.50065 5.94346L9.02925 9.47206C9.2896 9.73241 9.71171 9.73241 9.97206 9.47206C10.2324 9.21171 10.2324 8.7896 9.97206 8.52925L6.44346 5.00065L9.97206 1.47206Z"
        fill="#6C7275"
        fillOpacity="0.5"
      />
    ),
  },
  hashtag: {
    width: 20,
    height: 20,
    children: (
      <path
        d="M13 13h4-4V8H7v5h6v4-4H7V8H3h4V3v5h6V3v5h4-4v5zm-6 0v4-4H3h4z"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  },
  doc: {
    height: 18,
    width: 18,
    children: (
      <>
        <path
          fill="white"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5446 3.00375C15.6453 3.00375 16.5375 3.96006 16.5375 5.13972V13.6836C16.5375 14.8633 15.6457 15.8196 14.5451 15.8196H11.5554C10.3999 15.8196 9.5625 16.5038 9.5625 16.5038V5.13972C9.5625 3.96006 10.4547 3.00375 11.5554 3.00375H14.5446Z"
        />
        <path
          fill="white"
          d="M1.49622 5.13972C1.49622 3.96006 2.38845 3.00375 3.48907 3.00375H6.47836C7.57898 3.00375 8.47122 3.96006 8.47122 5.13972V16.5038C8.47122 16.5038 7.63385 15.8196 6.47836 15.8196H3.48865C2.38802 15.8196 1.49622 14.8633 1.49622 13.6836V5.13972Z"
          fillOpacity="0.4"
        />
      </>
    ),
  },
}

type IconProps = {
  style?: CSSProperties
  icon: keyof typeof icons
}

const Icon = ({ style, icon }: IconProps) => {
  const { width, height, children } = icons[icon]
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
    >
      {children}
    </svg>
  )
}

export default Icon
