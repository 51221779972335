import React from "react"
import ReactDOM from "react-dom/client"

import Router from "./components/router"

import "./index.css"

const rootElement = document.getElementById("root")

if (!rootElement) {
  throw new Error(
    `Element with id "root" was not found in the DOM tree, please add it in public/index.html's body`
  )
}

const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
)
