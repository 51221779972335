import { Description, Header, Heading } from "./styles"

const Search = () => (
  <Header>
    <Heading>Search</Heading>
    <Description>Designed to answer support related questions</Description>
  </Header>
)

export default Search
