import { Link } from "../app/styles"

type ReferenceProps = {
  reference: string
}

const Reference = ({ reference }: ReferenceProps) => (
  <li>
    <Link href={reference}>{reference}</Link>
  </li>
)

export default Reference
