import type { HitAdditionalProps, SearchResult } from "../../../shared-types"

type Hit2 = {
  readonly objectID: string
  readonly _snippetResult?: {
    readonly title?: {
      readonly value: string
      readonly matchLevel: "none" | "partial" | "full"
    }
    readonly content?: {
      readonly value: string
      readonly matchLevel: "none" | "partial" | "full"
    }
  }
} & HitAdditionalProps

type GetURLBasedOnLvlOptions = {
  searchResultBaseURL: string
  objectID: string
  isLvl1: boolean
}

const getURLBasedOnLvl = ({
  searchResultBaseURL,
  objectID,
  isLvl1,
}: GetURLBasedOnLvlOptions) => {
  const splitObjectID = objectID.split("#")

  const lastObjectIDFragment = splitObjectID.pop()

  const lvl1URLPath = splitObjectID.join("/")

  const lvl1URL = `${searchResultBaseURL}/docs/${lvl1URLPath}`

  if (isLvl1) {
    return `${lvl1URL}/${lastObjectIDFragment}`
  }

  return `${lvl1URL}#${lastObjectIDFragment}`
}

const getHitsResults = (hits: Hit2[], searchResultBaseURL: string) => {
  const results: SearchResult[] = []

  hits.forEach((hit) => {
    if (!hit._snippetResult) {
      return
    }

    const { content: highlightContent } = hit._snippetResult

    let title = hit.hierarchy[hit.type]
    let content = ""

    const contentIsHighlighted = highlightContent?.matchLevel !== "none"

    if (contentIsHighlighted) {
      content = highlightContent?.value || content
    }

    if (hit.type === "lvl1") {
      const result = results.find(
        ({ category }) => category === hit.hierarchy.lvl0
      )

      if (result) {
        return
      }

      results.push({
        category: hit.hierarchy.lvl0,
        title,
        content,
        type: "lvl1",
        url: getURLBasedOnLvl({
          searchResultBaseURL,
          objectID: hit.objectID,
          isLvl1: true,
        }),
        id: hit.objectID,
      })

      return
    }

    const parent = results.find(
      (result) => result.category === hit.hierarchy.lvl0
    )

    if (parent) {
      if (typeof parent.content === "string") {
        parent.content = []
      }

      parent.content.push({
        title,
        content,
        lvl2Title: hit.type === "lvl2" ? undefined : hit.hierarchy.lvl2,
        url: getURLBasedOnLvl({
          searchResultBaseURL,
          objectID: hit.objectID,
          isLvl1: false,
        }),
        id: hit.objectID,
      })

      return
    }

    const hitSplitObjectID = hit.objectID.split("#")
    hitSplitObjectID.pop()
    const parentObjectID = hitSplitObjectID.join("#")

    results.push({
      category: hit.hierarchy.lvl0,
      title: hit.hierarchy.lvl1,
      url: getURLBasedOnLvl({
        searchResultBaseURL,
        objectID: parentObjectID,
        isLvl1: true,
      }),
      type: "lvl1",
      content: [
        {
          title,
          content,
          lvl2Title: hit.type === "lvl2" ? undefined : hit.hierarchy.lvl2,
          url: getURLBasedOnLvl({
            searchResultBaseURL,
            objectID: hit.objectID,
            isLvl1: false,
          }),
          id: hit.objectID,
        },
      ],
      id: parentObjectID,
    })
  })

  return results
}

export default getHitsResults
