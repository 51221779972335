import { useState } from "react"

import Icon from "../icon"

import {
  Container,
  Rating,
  RatingOptionsContainer,
  ReferencesToggle,
  ReferencesList,
} from "./styles"

import RatingOption, { type RatingState } from "./rating-option"
import Reference from "./reference"

type FooterProps = {
  contentExists: boolean
  references?: string[]
  responseId?: string
}

const Footer = ({ references, contentExists, responseId }: FooterProps) => {
  const [listIsVisible, setListVisibility] = useState(false)
  const [rating, setRating] = useState<RatingState>(null)

  return (
    <>
      <Container>
        {contentExists ? (
          <>
            {references?.length ? (
              <ReferencesToggle
                onClick={() => setListVisibility(!listIsVisible)}
              >
                <Icon icon={listIsVisible ? "chevronDown" : "chevronRight"} />
                See verified sources
              </ReferencesToggle>
            ) : null}
            {responseId && (
              <Rating>
                Was this response helpful?
                <RatingOptionsContainer>
                  <RatingOption
                    rating={1}
                    setRating={setRating}
                    currentRating={rating}
                    responseId={responseId}
                  />
                  <RatingOption
                    rating={-1}
                    setRating={setRating}
                    currentRating={rating}
                    responseId={responseId}
                  />
                </RatingOptionsContainer>
              </Rating>
            )}
          </>
        ) : null}
      </Container>
      {references?.length && listIsVisible ? (
        <ReferencesList>
          {references.map((reference, index) => (
            <Reference
              reference={reference}
              key={index}
            />
          ))}
        </ReferencesList>
      ) : null}
    </>
  )
}

export default Footer
