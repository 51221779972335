import type { Dispatch, SetStateAction } from "react"

import { RatingOption as StyledRatingOption } from "./styles"

import { useAppState } from "../../context"

import { sendRatingRequest } from "../../api"

import type { RatingOptions } from "../../shared-types"

type Rating = 1 | -1

export type RatingState = Rating | null

type RatingOptionProps = {
  rating: Rating
  currentRating: Rating | null
  responseId: string
  setRating: Dispatch<SetStateAction<Rating | null>>
}

const RatingOption = ({
  currentRating,
  rating,
  responseId,
  setRating,
}: RatingOptionProps) => {
  const { settings, team, uid, session, isExternal } = useAppState()

  const handleRatingRequest = async () => {
    setRating(rating)

    try {
      let ratingOptions: RatingOptions = {
        responseId,
        rating,
      }

      if (!isExternal) {
        ratingOptions = { ...ratingOptions, team, uid, session }
      }

      await sendRatingRequest(ratingOptions)
    } catch {}
  }

  return (
    <StyledRatingOption
      type="button"
      onClick={handleRatingRequest}
      style={
        currentRating === rating
          ? { color: settings?.widgetPrimaryColor }
          : undefined
      }
    >
      {rating === 1 ? "Yes" : "No"}
    </StyledRatingOption>
  )
}

export default RatingOption
