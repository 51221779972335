import { useEffect, useState } from "react"

import { useUserId } from "."

import type { ChatMessage, SearchState, FormType } from "../shared-types"

type UseAppStateValuesOptions = {
  searchEnabled: boolean
  isForum: boolean | undefined
}

const useAppStateValues = ({
  searchEnabled,
  isForum,
}: UseAppStateValuesOptions) => {
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [searchState, setSearchState] = useState<SearchState>({
    results: null,
    error: "",
    loading: false,
    currentQueryId: "",
  })

  const [formType, setFormType] = useState<FormType>(
    searchEnabled ? "search" : "chat"
  )
  const [session, setSession] = useState(new Date().getTime().toString())

  let uid = useUserId()

  if (isForum) {
    uid = `paiahc_${uid}`
  }

  const regenerateConversation = () => {
    setSession(new Date().getTime().toString())
    setMessages([])
  }

  const handleRegenerateMessage = (event: MessageEvent) => {
    if (event.data === "regenerate") {
      regenerateConversation()
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleRegenerateMessage)

    return () => window.removeEventListener("message", handleRegenerateMessage)
  }, [])

  return {
    messages,
    setMessages,
    searchState,
    setSearchState,
    formType,
    setFormType,
    session,
    regenerateConversation,
    uid,
  }
}

export default useAppStateValues
