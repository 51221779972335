import styled from "styled-components"

type SearchEnabledProps = {
  $searchEnabled?: boolean
  $isFree?: boolean
}

export const Container = styled.div<SearchEnabledProps>`
  margin-top: -7px;
  padding: 0 20px;
  margin-bottom: ${({ $searchEnabled, $isFree }) =>
    !$searchEnabled && !$isFree ? "20px" : "0"};
  margin-bottom: 20px;
`

export const StyledInputContainer = styled.div`
  position: relative;
`

export const StyledInput = styled.input<SearchEnabledProps>`
  color: #141718;
  box-sizing: border-box;
  font-family: Inter;
  width: 100%;
  background-color: transparent;
  padding: ${({ $searchEnabled }) =>
    !$searchEnabled ? "8px 8px 8px 13px" : "12px 12px 12px 14px"};
  padding-right: 42px;
  border-radius: 6px;
  border: 2px solid #e8ecef;
  font-size: ${({ $searchEnabled }) => (!$searchEnabled ? "14px" : "15px")};
  line-height: 23px;
  outline: none;
  &::placeholder {
    color: #6c7275;
  }
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
    color: #14171870;
    &::placeholder {
      color: #6c727570;
    }
  }
`

export const SuggestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  position: sticky;
`

export const Suggestion = styled.div<SearchEnabledProps>`
  color: #232627;
  font-size: ${({ $searchEnabled }) => (!$searchEnabled ? "12px" : "14px")};
  font-weight: 500;
  padding: 2px 8px;
  line-height: ${({ $searchEnabled }) => (!$searchEnabled ? "20px" : "24px")};
  background-color: #e8ecef;
  transition: background-color 0.3s ease-in-out;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #c5cfd7;
  }
`

export const KbFilterContainer = styled.div`
  margin-top: 1rem;
`

export const KBLabel = styled.div`
  font-size: 14px;
  color: #6c7275;
  margin-bottom: 0.25rem;
`

type SubmitProps = {
  $formIsChat: boolean
}

export const Submit = styled.button<SubmitProps>`
  top: 50%;
  right: 8px;
  display: flex;
  transform: translate(-50%, -50%);
  background-color: inherit;
  cursor: pointer;
  position: absolute;
  border: none;
  padding: 0;
  & path {
    fill: ${({ $formIsChat }) =>
      $formIsChat ? "var(--paia-color-primary)" : "none"};
    stroke: ${({ $formIsChat }) =>
      $formIsChat ? "none" : "var(--paia-color-primary)"};
  }
  &:disabled {
    cursor: not-allowed;
    & path {
      fill: ${({ $formIsChat }) => ($formIsChat ? "#6c727550" : "none")};
      stroke: ${({ $formIsChat }) => ($formIsChat ? "none" : "#6c727550")};
    }
  }
`

type WidgetSwitchProps = {
  $formIsChat: boolean
}

export const WidgetSwitch = styled.button<WidgetSwitchProps>`
  width: 100%;
  margin-top: 1rem;
  background-color: #141718;
  padding: 0.5rem 1rem;
  padding-right: ${({ $formIsChat }) => ($formIsChat ? "1rem" : "0.5rem")};
  padding-left: ${({ $formIsChat }) => ($formIsChat ? "0.5rem" : "1rem")};
  border-radius: 6px;
  text-align: left;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #e8ecef;
  & svg {
    fill: #e8ecef;
  }
`
