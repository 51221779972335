import type { Settings, WidgetSettings } from "../../shared-types"

const getDefaultSettings = (
  settings: Partial<Settings> | undefined,
  searchEnabled?: boolean
) => {
  const defaultSettings: WidgetSettings = {
    widgetType: "search",
    widgetPrimaryColor: "#01EA97",
    widgetName: "Ai Assistant",
    widgetInputPlaceholder: searchEnabled
      ? "Ask me anything"
      : "Search for anything",
    widgetSuggestedQuestions: [],
    widgetWelcomeMessage: "Hello! How can I help you today?",
    widgetPublicPath: "",
  }

  if (!settings) {
    return defaultSettings
  }

  const {
    widgetPrimaryColor,
    widgetName,
    widgetInputPlaceholder,
    widgetSuggestedQuestions,
    widgetWelcomeMessage,
    widgetType,
    widgetPublicPath,
  } = settings

  return {
    ...settings,
    widgetType: widgetType || defaultSettings.widgetType,
    widgetPrimaryColor:
      widgetPrimaryColor || defaultSettings.widgetPrimaryColor,
    widgetName: widgetName || defaultSettings.widgetName,
    widgetInputPlaceholder:
      widgetInputPlaceholder || defaultSettings.widgetInputPlaceholder,
    widgetSuggestedQuestions:
      widgetSuggestedQuestions || defaultSettings.widgetSuggestedQuestions,
    widgetWelcomeMessage:
      widgetWelcomeMessage || defaultSettings.widgetWelcomeMessage,
    widgetPublicPath: widgetPublicPath || defaultSettings.widgetPublicPath,
  }
}

export default getDefaultSettings
