import {
  createContext,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
} from "react"

import { useAppStateValues } from "../hooks"

import type {
  Settings,
  ChatMessage,
  SearchState,
  FormType,
  KbSource,
} from "../shared-types"

type AppProviderProps = {
  searchEnabled: boolean
  children: ReactNode
  settings: Settings
  isExternal: boolean
  team: string
  assistant?: string
  isFree: boolean
  isForum?: boolean
  isVisible?: boolean
  searchResultBaseURL?: string
  kbSources?: KbSource[]
  onClose?: () => void
  validate?: () => boolean
}

type ContextType = Omit<AppProviderProps, "children"> & {
  formType: FormType
  setFormType: Dispatch<SetStateAction<FormType>>
  messages: ChatMessage[]
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>
  searchState: SearchState
  setSearchState: Dispatch<SetStateAction<SearchState>>
  uid: string
  session: string
  regenerateConversation: () => void
}

const AppContext = createContext<ContextType | undefined>(undefined)

const AppProvider = ({ children, ...props }: AppProviderProps) => {
  const appStateValues = useAppStateValues({
    searchEnabled: props.searchEnabled,
    isForum: props.isForum,
  })

  return (
    <AppContext.Provider
      value={{
        ...props,
        ...appStateValues,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppState = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error("useAppState must be used within an AppProvider")
  }

  return context
}

export { AppProvider, useAppState }
