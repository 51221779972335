import { useEffect, useRef } from "react"
import Select from "react-select"

import {
  Container,
  KBLabel,
  KbFilterContainer,
  StyledInput,
  StyledInputContainer,
  Submit,
  Suggestion,
  SuggestionsContainer,
  WidgetSwitch,
} from "./styles"

import { useInput } from "./hooks"
import Icon from "../icon"
import { useAppState } from "../../context"

const Input = () => {
  const {
    settings,
    searchEnabled,
    messages,
    formType,
    isFree,
    isForum,
    isVisible,
    isExternal,
    kbSources,
    setFormType,
    validate,
  } = useAppState()

  const inputRef = useRef<HTMLInputElement>(null)

  const forumSubmitEnabled = isForum ? formType === "chat" : true

  const {
    value,
    filterValue,
    messageIsProcessing,
    onChange,
    onKeyUp,
    sendMessage,
    handleFilterChange,
  } = useInput({ validate })

  const formIsChat = formType === "chat"

  const handleWidgetSwitch = () => setFormType(formIsChat ? "search" : "chat")

  const suggestedQuestions = settings.widgetSuggestedQuestions.filter(
    (question) =>
      Boolean(question) &&
      messages.every((message) => message.content !== question)
  )

  useEffect(() => {
    if (isVisible && !isExternal) {
      inputRef.current?.focus()
    }
  }, [isVisible])

  return (
    <Container $searchEnabled={searchEnabled} $isFree={isFree}>
      {formIsChat && (
        <SuggestionsContainer>
          {suggestedQuestions.map((text, index) => {
            const onClick = () => {
              if (validate) {
                if (!validate()) {
                  return
                }
              }
              sendMessage(text)
            }

            return (
              <Suggestion
                $searchEnabled={searchEnabled}
                key={index}
                onClick={onClick}
              >
                {text}
              </Suggestion>
            )
          })}
        </SuggestionsContainer>
      )}

      <StyledInputContainer>
        <StyledInput
          onFocus={validate}
          id="widget-input"
          $searchEnabled={searchEnabled}
          type="text"
          placeholder={settings.widgetInputPlaceholder}
          value={value}
          autoComplete="off"
          onChange={onChange}
          onKeyUp={onKeyUp}
          ref={inputRef}
        />
        {forumSubmitEnabled && (
          <Submit
            type="button"
            disabled={messageIsProcessing || !value.length}
            onClick={() => sendMessage()}
            $formIsChat={formIsChat}
          >
            <Icon
              icon={formIsChat ? "send" : "search"}
              style={
                !formIsChat ? { width: "20px", height: "20px" } : undefined
              }
            />
          </Submit>
        )}
      </StyledInputContainer>

      {kbSources?.length && (
        <KbFilterContainer>
          <KBLabel>Knowledge Base Filter</KBLabel>
          <Select
            isClearable={true}
            value={filterValue}
            onChange={handleFilterChange}
            options={kbSources.map(({ id, title }) => ({
              value: id,
              label: title,
            }))}
            className="ask-select"
            classNamePrefix="select"
          />
        </KbFilterContainer>
      )}

      {searchEnabled && (
        <WidgetSwitch
          type="button"
          onClick={handleWidgetSwitch}
          $formIsChat={formIsChat}
        >
          {formIsChat ? (
            <>
              <Icon
                icon="chevronRight"
                style={{
                  width: "20px",
                  height: "20px",
                  transform: "rotate(180deg)",
                }}
              />
              Back to search
            </>
          ) : (
            <>
              Ask AI
              <Icon
                icon="chevronRight"
                style={{ width: "20px", height: "20px" }}
              />
            </>
          )}
        </WidgetSwitch>
      )}
    </Container>
  )
}

export default Input
