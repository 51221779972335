import { Link } from "../app/styles"
import { Container, LinkContainer } from "./styles"

import { useAppState } from "../../context"

const Footer = () => {
  const { searchEnabled, isFree } = useAppState()

  if (!searchEnabled && !isFree) return null

  if (!isFree) {
    return null
  }

  return (
    <Container>
      <LinkContainer className="widget-link-container">
        Powered by{" "}
        <Link
          className="widget-link"
          href="https://paia.co/?utm_source=widget&utm_medium=referral&utm_campaign=widget"
          target="_blank"
        >
          Paia
        </Link>
      </LinkContainer>
    </Container>
  )
}

export default Footer
