import { useAppState } from "../context"

const useHeaderValues = () => {
  const { settings, isExternal, onClose, regenerateConversation } =
    useAppState()

  const showRegenerateButton = Boolean(isExternal && !onClose)

  const handleClick = () => {
    regenerateConversation()

    if (onClose) {
      onClose()
    }

    if (!showRegenerateButton) {
      window.top?.postMessage("paia-widget-close-iframe", "*")
    }
  }

  return { settings, showRegenerateButton, handleClick }
}

export default useHeaderValues
