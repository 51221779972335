import { useAssistantSettings } from "../../hooks"

import { AppProvider } from "../../context"

import ErrorPage from "../error-page"
import Widget from "../widget"
import Loader from "../loader"

import { GlobalStyles } from "./styles"

import getDefaultSettings from "./get-default-settings"

import type { WidgetSettings } from "../../shared-types"

const InternalApp = () => {
  const { loading, error, widgetSettings, teamId, assistantId, isFree } =
    useAssistantSettings()

  const searchEnabled = widgetSettings?.widgetType === "search"

  if (error) {
    return <ErrorPage />
  }

  if (loading || !widgetSettings) {
    return <Loader fixedAndCentered />
  }

  const defaultSettings = getDefaultSettings(
    widgetSettings as WidgetSettings,
    searchEnabled
  )

  return (
    <>
      <GlobalStyles $primaryColor={defaultSettings.widgetPrimaryColor} />

      <AppProvider
        isFree={isFree}
        team={teamId}
        assistant={assistantId}
        isExternal={false}
        settings={defaultSettings}
        searchEnabled={searchEnabled}
        isVisible={true}
      >
        <Widget />
      </AppProvider>
    </>
  )
}

export default InternalApp
