import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const Description = styled.p`
  margin: 0;
  font-size: 1.5rem;
`

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: black;
  width: 34px;
  height: 34px;
  flex: none;
  display: flex;
  justify-content: right;
  & svg {
    border: 3px solid rgb(255, 255, 255);
    border-radius: 50%;
    background-color: var(--paia-color-primary);
  }
`
