import Icon from "../icon"
import { Container, Description, IconWrapper } from "./styles"

const ErrorPage = () => (
  <Container>
    <IconWrapper>
      <Icon icon="logo" />
    </IconWrapper>
    <Description>The widget could not be loaded</Description>
  </Container>
)

export default ErrorPage
