import {
  useEffect,
  useState,
  useRef,
  type ChangeEvent,
  type KeyboardEvent,
} from "react"
import { type SingleValue } from "react-select"

import { useAppState } from "../../../context"

import useAlgolia from "./use-algolia"

import {
  sendMessageRequest,
  sendSearchRequest,
  type CommonOptions,
  type MessageOptions,
} from "../../../api"

import type { ChatMessage, SearchResult } from "../../../shared-types"

type useInputOptions = {
  validate?: () => boolean | undefined
}

type FilterValue = {
  value: string
  label: string
}

const useInput = ({ validate }: useInputOptions) => {
  const [value, setValue] = useState("")

  const [filterValue, setFilterValue] = useState<SingleValue<FilterValue>>()

  const handleFilterChange = (newValue: SingleValue<FilterValue>) =>
    setFilterValue(newValue)

  const [messageIsProcessing, setMessageIsProcessing] = useState(false)

  const { searchAlgolia } = useAlgolia()

  const {
    settings,
    messages,
    formType,
    isForum,
    team,
    assistant,
    isExternal,
    uid,
    session,
    setSearchState,
    setMessages,
  } = useAppState()

  const searchMessageRef = useRef<string>("")

  const forumSubmitEnabled = isForum ? formType === "chat" : true

  const formIsChat = formType === "chat"

  const sendMessage = async (message?: string) => {
    const question = message || value

    if (messageIsProcessing || !question) {
      return
    }

    if (formIsChat) {
      const newMessages: ChatMessage[] = [
        ...messages,
        { role: "user", content: question },
        { role: "assistant", content: "" },
      ]

      const options: Omit<MessageOptions, "updateMessageContent"> & {
        uid?: string
      } = {
        question: question,
        team,
        history: newMessages.slice(0, -1),
        session,
      }

      if (assistant) {
        options.assistant = assistant
      }

      if (isExternal) {
        options.settings = settings
      } else {
        options.uid = uid
      }

      setValue("")
      setMessages(newMessages)
      setMessageIsProcessing(true)

      try {
        const { responseId } = await sendMessageRequest({
          usedSources: filterValue ? [filterValue?.value] : undefined,
          updateMessageContent: (message) => {
            const lastMessage = newMessages[newMessages.length - 1]
            lastMessage.content = message
            setMessageIsProcessing(false)

            setMessages([...newMessages.slice(0, -1), lastMessage])
          },
          ...options,
        })

        setMessages((messages) => {
          const newMessages = messages.slice()

          newMessages[newMessages.length - 1].responseId = responseId

          return newMessages
        })
      } catch {
        setMessages([
          ...newMessages.slice(0, -1),
          {
            role: "assistant",
            content: "Sorry, I didn't get that. Can you try again?",
          },
        ])
        setMessageIsProcessing(false)
      }
    } else {
      setSearchState({
        results: [],
        error: "",
        loading: true,
        currentQueryId: "",
      })

      try {
        const options: CommonOptions = {
          question: question,
          team,
          session,
        }

        if (isExternal) {
          options.settings = settings
        }

        if (assistant) {
          options.assistant = assistant
        }

        const response = await sendSearchRequest(options)

        const results: SearchResult[] = response.map(({ url, text }) => ({
          url,
          content: text,
          title: url,
        }))

        setSearchState({
          results,
          error: results.length ? "" : "No results found",
          loading: false,
          currentQueryId: "",
        })
      } catch {
        setSearchState({
          results: [],
          error: "Internal server error, please try again later",
          loading: false,
          currentQueryId: "",
        })
      }
    }
  }

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (validate) {
      if (!validate()) {
        return
      }
    }

    if (e.key === "Enter" && forumSubmitEnabled) {
      sendMessage()
    }
  }

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (validate) {
      if (!validate()) {
        return
      }
    }

    if (e.target.value.length > 600) {
      return
    }

    setValue(e.target.value)

    await searchAlgolia(e.target.value, filterValue?.value)
  }

  useEffect(() => {
    if (formType === "chat" && isForum) {
      if (value.trim().length) {
        searchMessageRef.current = value
        sendMessage(value)
      }

      return
    }

    setValue(searchMessageRef.current)
  }, [formType])

  useEffect(() => {
    if (formIsChat || !isForum) {
      return
    }

    ;(async () => {
      await searchAlgolia(value, filterValue?.value)
    })()
  }, [filterValue, isForum, formIsChat])

  return {
    value,
    filterValue,
    messageIsProcessing,
    onChange,
    onKeyUp,
    sendMessage,
    handleFilterChange,
  }
}

export default useInput
