import Search from "./search"
import Chat from "./chat"
import Input from "../input"

import { useAppState } from "../../context"

const Body = () => {
  const { formType } = useAppState()

  return (
    <>
      {formType === "search" ? <Search /> : <Chat />}

      <Input />
    </>
  )
}

export default Body
