import cloneDeep from "lodash.clonedeep"

import type { Settings, ChatMessage, RatingOptions } from "../shared-types"

export type CommonOptions = {
  team: string
  assistant?: string
  session?: string
  question: string
  settings?: Settings
}

export type MessageOptions = CommonOptions & {
  history?: ChatMessage[]
  usedSources?: string[]
  updateMessageContent: (content: string) => void
}

type MessageResponse = {
  content: string
  reference?: string[]
  responseId?: string
}

type SearchResponse = {
  url: string
  text: string
}

const getAssistantSettings = (settings: Settings | undefined) => {
  if (!settings) {
    return null
  }

  const newSettings = cloneDeep(settings)

  Object.keys(newSettings).forEach((key) => {
    if (key.includes("widget")) {
      delete newSettings[key as keyof typeof newSettings]
    }
  })

  if (Object.keys(newSettings).length) {
    return newSettings
  }

  return null
}

// if REACT_APP_API_URL is undefined then use the NEXT_PUBLIC_PAIA_API_URL from my
const baseUrl = process.env.REACT_APP_API_URL || process.env.NEXT_PUBLIC_API_URL
//const baseUrl = "http://127.0.0.1:5001/uicore-ai/us-central1/api/v1"

export const sendMessageRequest = async ({
  updateMessageContent,
  assistant,
  settings,
  ...options
}: MessageOptions): Promise<MessageResponse> => {
  const assistantSettings = getAssistantSettings(settings)

  const requestOptions = assistantSettings
    ? { ...options, settings: assistantSettings }
    : options

  const requestBody = assistant
    ? { ...requestOptions, assistant }
    : requestOptions

  const response = await fetch(baseUrl + "/assistant", {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
    },
  })

  const reader = response.body!.getReader()

  let responseData = {
    content: "",
    reference: [],
    responseId: "",
  } as MessageResponse

  while (true) {
    const { value } = await reader.read()
    const chunk = new TextDecoder().decode(value)

    // Split the current chunk by new lines
    const chunks = chunk.trim().split("\n")

    for (const chunkData of chunks) {
      // Skip empty chunks (it may happen from time to time to get some)
      if (chunkData === "") continue

      // If the chunk is "END", it means that the stream is complete
      if (chunkData === "END") {
        return responseData
      }

      const parsedChunk = JSON.parse(chunkData)

      // Handle the chunk data and return the response to the UI
      if (parsedChunk.chunk) {
        responseData.content += parsedChunk.chunk
        updateMessageContent(responseData.content)
      }
      if (parsedChunk.reference) {
        responseData.reference = parsedChunk.reference
      }
      if (parsedChunk.responseId) {
        responseData.responseId = parsedChunk.responseId
      }
    }
  }
}

export const sendSearchRequest = async ({
  settings,
  ...data
}: CommonOptions) => {
  const assistantSettings = getAssistantSettings(settings)

  const requestBody = assistantSettings
    ? { ...data, settings: assistantSettings }
    : data

  const response = await fetch(baseUrl + "/search", {
    method: "POST",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.ok) {
    const responseData = await response.json()
    return responseData as SearchResponse[]
  } else {
    throw new Error("Failed to send search request.")
  }
}

export const sendRatingRequest = async (options: RatingOptions) => {
  const response = await fetch(baseUrl + "/rating", {
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (response.ok) {
    return
  } else {
    throw new Error("Failed to send feedback request.")
  }
}
