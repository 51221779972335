import { Content, Text, MessageItem } from "./styles"

// import Icon from "../icon"
import Loader from "../loader"

import { useAppState } from "../../context"

import Footer from "./footer"

import type { ChatMessage } from "../../shared-types"

type MessageProps = Omit<ChatMessage, "rating"> & {
  isInitialAssistantMessage?: boolean
}

const transformCode = (text: string) => {
  // check for code block and add pre tag and also remove language type
  text = text.replace(/```([a-z]+)?\n([^]+?)\n```/g, "<pre>$2</pre>")
  text = text.replace(/```([^```]+)```/g, "<pre>$1</pre>")
  text = text.replace(/`([^`]+)`/g, "<code>$1</code>")
  text = text.replace(/((https?:\/\/)[^\s]+)/g, '<a href="$1">$1</a>')
  // text = text.replace(/\n/g, '<br />');
  return text
}

const Message = ({
  content,
  role,
  responseId,
  isInitialAssistantMessage,
  reference,
}: MessageProps) => {
  const isUser = role === "user"

  const { searchEnabled, isExternal } = useAppState()

  return (
    <MessageItem $isUser={isUser}>
      <Content
        $isUser={isUser}
        $searchEnabled={searchEnabled}
        $isExternal={isExternal}
      >
        {content ? (
          <Text
            dangerouslySetInnerHTML={{ __html: transformCode(content).trim() }}
          />
        ) : (
          <Loader />
        )}
      </Content>
      {!isUser && !isInitialAssistantMessage && (
        <Footer
          responseId={responseId}
          references={reference}
          contentExists={Boolean(content)}
        />
      )}
    </MessageItem>
  )
}

export default Message
