import styled from "styled-components"

export const Header = styled.div`
  padding: 0 20px;
  margin-top: 20px;
`

export const Heading = styled.div`
  font-size: 28px;
  color: #141718;
  margin: 0px;
  font-weight: bold;
`

export const Description = styled.div`
  font-size: 14px;
  color: #6c7275;
  margin-top: 10px;
  margin-bottom: 20px;
`
