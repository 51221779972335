import Icon from "../../../icon"

import algoliaAnalytics from "search-insights"

import {
  Item,
  Anchor,
  Description,
  Title,
  ContentList,
  IconContainer,
  TypographyContainer,
  TitleContainer,
} from "./styles"

import { useAppState } from "../../../../context"

import type {
  SearchResult,
  SearchResultContent,
} from "../../../../shared-types"

algoliaAnalytics("init", {
  appId: "WUUJJWV07R",
  apiKey: "7e445d73a5d2d7e8996c1a27bf707c37",
})

const Link = ({
  url,
  title,
  content,
  category,
  type,
  id,
  position,
}: SearchResult & Pick<SearchResultContent, "lvl2Title">) => {
  const { isForum, team, uid, searchState } = useAppState()
  const displayedTitle = category ? `${category} > ${title}` : title

  const recordAlgoliaClickEvent = () =>
    algoliaAnalytics("clickedObjectIDsAfterSearch", {
      userToken: uid,
      index: team,
      eventName: "Search result clicked",
      queryID: searchState.currentQueryId,
      objectIDs: [id as string],
      positions: [position as number],
    })

  return (
    <Item>
      <Anchor onClick={recordAlgoliaClickEvent} href={url}>
        {isForum && (
          <IconContainer>
            <Icon
              icon={type === "lvl1" ? "doc" : "hashtag"}
              style={{ width: 15, height: 15 }}
            />
          </IconContainer>
        )}

        <TypographyContainer>
          <TitleContainer>
            <Title
              $isForum={isForum}
              dangerouslySetInnerHTML={{ __html: displayedTitle as string }}
            />
          </TitleContainer>

          {typeof content === "string" && content.length ? (
            <Description dangerouslySetInnerHTML={{ __html: content }} />
          ) : null}
        </TypographyContainer>
      </Anchor>

      {Array.isArray(content) && (
        <ContentList>
          {content.map((props, index) => (
            <Link
              position={(position as number) + index + 1}
              key={index}
              {...props}
            />
          ))}
        </ContentList>
      )}
    </Item>
  )
}

export default Link
