import { useEffect, useState } from "react"

import algoliaSearch, { type SearchIndex } from "algoliasearch"

import { useAppState } from "../../../context"
import getHitsResults from "../utilities/get-hits-results"

import type { HitAdditionalProps, SearchResult } from "../../../shared-types"

const algoliaApiKey = "7e445d73a5d2d7e8996c1a27bf707c37"
const algoliaAppId = "WUUJJWV07R"

const useAlgolia = () => {
  const { isForum, team, formType, searchResultBaseURL, setSearchState } =
    useAppState()

  const formIsChat = formType === "chat"

  const [algoliaIndex, setAlgoliaIndex] = useState<SearchIndex | undefined>()

  useEffect(() => {
    if (!isForum) {
      return
    }

    const algoliaClient = algoliaSearch(algoliaAppId, algoliaApiKey)

    const algoliaIndex = algoliaClient.initIndex(team)

    setAlgoliaIndex(algoliaIndex)
  }, [isForum])

  const searchAlgolia = async (
    query: string,
    filteredSourceId: string | undefined
  ) => {
    if (isForum && !formIsChat) {
      if (!query.trim()) {
        setSearchState({
          loading: false,
          results: null,
          error: "",
          currentQueryId: "",
        })
        return
      }

      setSearchState({
        loading: true,
        results: [],
        error: "",
        currentQueryId: "",
      })

      const searchFilter = filteredSourceId
        ? { filters: `sourceId:${filteredSourceId}` }
        : null

      const { hits, queryID } = await (
        algoliaIndex as SearchIndex
      ).search<HitAdditionalProps>(query, {
        ...searchFilter,
        clickAnalytics: true,
      })

      const results: SearchResult[] = getHitsResults(
        hits,
        searchResultBaseURL as string
      )

      setSearchState({
        loading: false,
        results,
        error: "",
        currentQueryId: queryID as string,
      })
    }
  }

  return { searchAlgolia }
}

export default useAlgolia
