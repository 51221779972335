import styled from "styled-components"

export const Item = styled.li`
  border-radius: 12px;
  width: 100%;
`

export const Anchor = styled.a`
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  color: #141718;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  line-height: 24px;
  letter-spacing: -0.02rem;
  transition: background-color 0.2s ease-in-out;
  background-color: #e8ecef50;
  &:hover {
    color: #141718;
    background-color: #e8ecef;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

type TitleProps = {
  $isForum?: boolean
}

export const Title = styled.div<TitleProps>`
  font-size: 12px;
  font-weight: ${({ $isForum }) => ($isForum ? "500" : "700")};
  & em {
    font-style: normal;
    font-weight: 700;
    color: #141718;
  }
`

export const IconContainer = styled.div`
  border-radius: 0.35rem;
  background-color: #141718;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 0.5rem;
  & svg path {
    stroke: white;
  }
`

export const ContentList = styled.ul`
  border-left: 2px solid #e8ecef;
  list-style: none;
  padding-left: 15px;
  margin: 5px 0px 5px 20px;
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const TypographyContainer = styled.div`
  overflow: hidden;
`

export const Description = styled.p`
  font-size: 12px;
  line-height: 12x;
  letter-spacing: -0.02rem;
  font-weight: 500;
  color: #6c727575;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & em {
    font-style: normal;
    font-weight: 700;
    color: #141718;
  }
`
