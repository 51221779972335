import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: "flex-end";
  margin: 13px 0 17px;
  padding: 0 20px;
`

export const LinkContainer = styled.div`
  font-size: 12px;
  color: #6c7275;
  font-weight: 400;
  line-height: 12px;
`
