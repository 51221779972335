import Message from "../../message"

import { useAppState } from "../../../context"
import {
  useInitalMessageMountDelay,
  useScrollToLastMessage,
} from "../../../hooks"

import { MessagesList, MessagesListContainer } from "./styles"

const Chat = () => {
  const { messages, searchEnabled, settings, isExternal } = useAppState()

  const { listRef } = useScrollToLastMessage(messages)

  const { mountInitialMessage } = useInitalMessageMountDelay()

  return (
    <MessagesListContainer $searchEnabled={searchEnabled}>
      <MessagesList
        ref={listRef}
        $searchEnabled={searchEnabled}
        $isExternal={isExternal}
      >
        {mountInitialMessage && (
          <Message
            role="assistant"
            content={settings.widgetWelcomeMessage}
            isInitialAssistantMessage
          />
        )}
        {messages.map((message, index) => (
          <Message key={index} {...message} />
        ))}
      </MessagesList>
    </MessagesListContainer>
  )
}

export default Chat
