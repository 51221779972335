import { useEffect, useState } from "react"
import { useLoaderData } from "react-router-dom"

import initializeFirestore from "../firebase"

import {
  doc,
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  type Firestore,
} from "firebase/firestore"

import type { Settings, WidgetSettings } from "../shared-types"

type LoaderData = {
  askCollectionId?: string
  publicPath?: string
}

type AskDoc = {
  teamId: string
  assistantId: string
  settings: Settings
  isFree: boolean
}

const firestore = initializeFirestore() as Firestore

const useAssistantSettings = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [widgetSettings, setWidgetSettings] = useState<WidgetSettings | null>(
    null
  )
  const [isFree, setIsFree] = useState(false)
  const [assistantId, setAssitantId] = useState("")
  const [teamId, setTeamId] = useState("")

  const { askCollectionId, publicPath } = useLoaderData() as LoaderData

  useEffect(() => {
    ;(async () => {
      try {
        if (askCollectionId) {
          const removeAskDocListener = onSnapshot(
            doc(firestore, "ask", askCollectionId),
            askDoc => {
              if (!askDoc.exists()) {
                setError(true)
                return
              }

              const { settings, teamId, assistantId, isFree } =
                askDoc.data() as AskDoc

              setIsFree(isFree)
              setAssitantId(assistantId)
              setTeamId(teamId)

              const {
                widgetPublicPath,
                widgetInputPlaceholder,
                widgetName,
                widgetPrimaryColor,
                widgetSuggestedQuestions,
                widgetType,
                widgetWelcomeMessage,
              } = settings

              setWidgetSettings({
                widgetPublicPath,
                widgetInputPlaceholder,
                widgetName,
                widgetPrimaryColor,
                widgetSuggestedQuestions,
                widgetType,
                widgetWelcomeMessage,
              })
            }
          )

          return removeAskDocListener
        }

        const askQuery = query(
          collection(firestore, "ask"),
          where("widgetPublicPath", "==", publicPath)
        )

        const askDocs = await getDocs(askQuery)

        const askDoc = askDocs.docs.find(doc => {
          const { settings } = doc.data() as AskDoc

          return settings.widgetPublicPath === publicPath
        })

        if (!askDoc) {
          setError(true)
          return
        }

        const removeAskDocListener = onSnapshot(
          doc(firestore, "ask", askDoc.id),
          askDoc => {
            if (!askDoc.exists()) {
              setError(true)
              return
            }

            const { settings, teamId, assistantId } = askDoc.data() as AskDoc

            setAssitantId(assistantId)
            setTeamId(teamId)
            setWidgetSettings(settings)
          }
        )

        return removeAskDocListener
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return { loading, error, widgetSettings, teamId, assistantId, isFree }
}

export default useAssistantSettings
