import styled, { keyframes } from "styled-components"

type ContainerProps = {
  $fixedAndCentered?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: ${({ $fixedAndCentered }) =>
    $fixedAndCentered ? "fixed" : "static"};
  width: ${({ $fixedAndCentered }) => ($fixedAndCentered ? "100%" : "auto")};
  height: ${({ $fixedAndCentered }) => ($fixedAndCentered ? "100%" : "auto")};
  top: 0;
  left: 0;
  align-self: center;
  align-items: ${({ $fixedAndCentered }) =>
    $fixedAndCentered ? "center" : "flex-start"};
  justify-content: ${({ $fixedAndCentered }) =>
    $fixedAndCentered ? "center" : "flex-start"};
  gap: 5px;
`

const scale = keyframes`
    0% {opacity: 1}
    100% {opacity: 0.1}
`

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #141718;
  animation: ${scale} 1s ease-in-out infinite;
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`
