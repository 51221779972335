import styled from "styled-components"

type ContentProps = {
  $isUser: boolean
  $isExternal?: boolean
  $searchEnabled?: boolean
}

export const MessageItem = styled.li<ContentProps>`
  display: inline-flex;
  flex-direction: column;
  max-width: 88%;
  align-self: ${({ $isUser }) => ($isUser ? "flex-end" : "flex-start")};
`

export const Content = styled.div<ContentProps>`
  line-height: 1.4;
  display: inline-flex;
  background-color: ${({ $isUser }) => ($isUser ? "#141718" : "#f3f5f7")};
  border-radius: 6px;
  padding: 13px 16px;
  font-size: ${({ $searchEnabled }) => ($searchEnabled ? "15px" : "14px")};
  color: ${({ $isUser }) => ($isUser ? "#f3f5f7" : "#141718")};
  gap: 10px;
  margin-left: ${({ $isUser }) => ($isUser ? "auto" : "0")};
`

export const Text = styled.div`
  white-space: pre-wrap;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  hyphens: none;
  overflow: auto;
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`
export const IconWrapp = styled.div`
  width: 34px;
  height: 34px;
  flex: none;
  display: flex;
  justify-content: right;
  & svg {
    border: 3px solid rgb(255, 255, 255);
    border-radius: 50%;
    background-color: var(--paia-color-primary);
  }
`
export const Container = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 10px;
  font-size: 12px;
`

export const ReferencesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  margin-top: 10px;
  list-style: none;
  padding: 0;
  width: 80%;
  & li a {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02rem;
    font-weight: 500;
  }
`

export const ReferencesToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #232627;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  & li {
    line-height: 20px;
  }
`

export const Rating = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  align-self: start;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #6c727575;
  letter-spacing: 0;
  gap: 10px;
`
export const RatingOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  background-color: #e8ecef;
  border-radius: 4px;
  border: none;
`

export const RatingOption = styled.button`
  padding: 0;
  line-height: 20px;
  color: #232627;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-weight: 500;
  font-size: 11px;
  &:hover {
    color: var(--paia-color-primary);
  }
`
