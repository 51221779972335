import { Close, Container } from "./styles"

import Icon from "../icon"

import { useHeaderValues } from "../../hooks"

const Header = () => {
  const { settings, showRegenerateButton, handleClick } = useHeaderValues()

  return (
    <Container>
      {settings.widgetName}
      <Close type="button" onClick={handleClick}>
        <Icon icon={showRegenerateButton ? "reload" : "close"} />
      </Close>
    </Container>
  )
}

export default Header
