import { Container, Dot } from "./styles"

type LoaderProps = {
  fixedAndCentered?: boolean
}

const Loader = ({ fixedAndCentered }: LoaderProps) => (
  <Container $fixedAndCentered={fixedAndCentered}>
    <Dot />
    <Dot />
    <Dot />
  </Container>
)

export default Loader
