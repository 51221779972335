import styled from "styled-components"

type SearchProps = {
  $searchEnabled?: boolean
  $isExternal?: boolean
}

export const MessagesListContainer = styled.div<SearchProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 0 20px;
  overflow-wrap: break-word;
  border-top-right-radius: ${({ $searchEnabled }) =>
    $searchEnabled ? "20px" : "0px"};
  border-top-left-radius: ${({ $searchEnabled }) =>
    $searchEnabled ? "20px" : "0px"};
  height: 100%;
  z-index: 0;

  &::after,
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, white, transparent);
    min-height: 40px;
    z-index: 40;
  }
  &::after {
    bottom: 0;
    top: auto;
    background: linear-gradient(0deg, white, transparent);
  }
`

export const MessagesList = styled.ul<SearchProps>`
  height: ${({ $searchEnabled, $isExternal }) =>
    $searchEnabled ? "auto" : $isExternal ? "460px" : "calc(100% - 50px)"};
  scroll-behavior: smooth;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 25px 0 30px 0;
  gap: ${({ $searchEnabled }) => ($searchEnabled ? "32px" : "20px")};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #6c727550;
    border-radius: 10px;
    transition: 0.3s;
  }
`
