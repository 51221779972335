import { useEffect } from "react"

import { Background, Dialog } from "./styles"

import Footer from "../footer"
import Body from "../body"
import Header from "../header"
import SearchResults from "../search-results"

import { useAppState } from "../../context"

const Widget = () => {
  const {
    formType,
    searchEnabled,
    isExternal,
    isVisible,
    isForum,
    regenerateConversation,
  } = useAppState()

  useEffect(() => regenerateConversation(), [isVisible])

  return (
    <Background
      $searchEnabled={searchEnabled}
      $isExternal={isExternal}
      $isVisible={isVisible}
      $isForum={isForum}
    >
      <Dialog $searchEnabled={searchEnabled} $isExternal={isExternal}>
        <Header />

        <Body />

        <Footer />

        {formType === "search" && searchEnabled && <SearchResults />}
      </Dialog>
    </Background>
  )
}

export default Widget
