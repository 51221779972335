import { createBrowserRouter, RouterProvider } from "react-router-dom"

import InternalApp from "../app/internal"
import ErrorPage from "../error-page"

const paramsLoader = async ({ params }: any) => params

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ErrorPage />,
      },
      {
        path: "/:publicPath",
        element: <InternalApp />,
        loader: paramsLoader,
      },
      {
        path: "embed/:askCollectionId",
        element: <InternalApp />,
        loader: paramsLoader,
      },
    ],
  },
])

const Router = () => <RouterProvider router={router} />

export default Router
