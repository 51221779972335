import { useEffect, useRef } from "react"
import type { ChatMessage } from "../shared-types"

const useScrollToLastMessage = (messages: ChatMessage[]) => {
  const listRef = useRef<HTMLUListElement>(null)
  useEffect(() => {
    if (!listRef.current) {
      return
    }

    listRef.current.scrollTop = listRef.current.scrollHeight
  }, [messages])

  return { listRef }
}

export default useScrollToLastMessage
