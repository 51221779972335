import styled from "styled-components"

type WidgetProps = {
  $searchEnabled: boolean
  $isExternal: boolean
  $isVisible?: boolean
  $isForum?: boolean
}

export const Background = styled.div<WidgetProps>`
  top: 0;
  left: 0;
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  position: ${({ $isExternal }) => ($isExternal ? "static" : "fixed")};
  width: ${({ $isExternal }) => ($isExternal ? "auto" : "100%")};
  height: ${({ $isExternal }) => ($isExternal ? "auto" : "100%")};
  z-index: 9999;
  background-color: ${({ $searchEnabled, $isExternal }) =>
    $searchEnabled && !$isExternal ? "#23252680" : "transparent"};
`

export const Dialog = styled.div<Omit<WidgetProps, "$isVisible">>`
  margin: ${({ $searchEnabled, $isExternal }) =>
    $searchEnabled && !$isExternal ? "5rem auto 0px auto" : "0"};
  font-family: "Inter";
  width: 100%;
  height: ${({ $searchEnabled }) => ($searchEnabled ? "auto" : "100%")};
  background-color: #fefefe;
  max-width: ${({ $searchEnabled }) => ($searchEnabled ? "650px" : "100%")};
  max-height: ${({ $searchEnabled }) => ($searchEnabled ? "72vh" : "100%")};
  padding-top: 0;
  border-radius: 6px;
  box-shadow: 0px 2px 81px rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
`
